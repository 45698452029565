var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-0 d-flex justify-end"},[_c('v-col',{attrs:{"cols":"12","sm":"2","align":"right"}},[_c('v-btn',{attrs:{"depressed":"","block":"","color":"success"},on:{"click":function($event){return _vm.setAside(null)}}},[_c('v-icon',{staticClass:"prefix-icon"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" Create ")],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.tableList,"options":_vm.options,"server-items-length":_vm.tableTotal,"loading":_vm.loading,"header-props":_vm.headerprops},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(item.id)+" ")]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.t(item.content))}})]}},{key:"item.distributor_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'distributor-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.distributor.slug)+" ")])]}},{key:"item.started_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.started_at)))])]}},{key:"item.expires_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.expires_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setAside(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteDistributorMessage(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.tableSelectedData),callback:function ($$v) {_vm.tableSelectedData=$$v},expression:"tableSelectedData"}})],1),(_vm.aside)?_c('distributor-message-aside',{attrs:{"resource":_vm.resource},on:{"changed":function($event){_vm.loadDistributorMessages(); _vm.aside = false}},model:{value:(_vm.aside),callback:function ($$v) {_vm.aside=$$v},expression:"aside"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }