<template>
  <v-form ref="formElem">
    <TranslationLocales />

    <v-card>
      <v-card-text>
        <v-select
          v-model="form.distributor_id"
          label="Distributor"
          item-text="label"
          item-value="value"
          outlined
          :items="distributorOptions"
        ></v-select>

        <v-select
          v-model="form.position"
          label="Message Display position"
          item-text="label"
          item-value="value"
          outlined
          :items="positionOptions"
        ></v-select>

        <translatable-editor
          v-model="form.content"
          label="Content"
          outlined
        />

        <h3 class="mt-2">
          From
        </h3>

        <v-date-picker v-model="form.started_at" />

        <v-divider class="mt-2"></v-divider>

        <h3 class="mt-2">
          To
        </h3>

        <v-date-picker v-model="form.expires_at" />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor, TranslationLocales } from '@/components'
import { useDistributor, useTranslatable } from '@/composables'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslatableEditor, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      ...field('distributor_id', null),
      ...field('content', useTranslatable('content')),
      ...field('position', 'product'),
      ...field('started_at', ''),
      ...field('expires_at', ''),

      // ...field('active', false),
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const positionOptions = ref([
      { label: 'Product', value: 'product' },
      { label: 'Merchant', value: 'merchant' },
    ])

    const { distributorOptions, fetchDistributors } = useDistributor()

    fetchDistributors({ take: 999 })

    return {
      form,
      formElem,
      validate,
      required,

      distributorOptions,
      positionOptions,
    }
  },
}
</script>
